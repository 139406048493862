import { pedantMarketApi } from "../clients"
import type { YandexPayOrderCreationParams, YandexPaySuccessOrderCreation } from "../types"

const createOrder = (params: YandexPayOrderCreationParams) => {
  return pedantMarketApi.post<YandexPaySuccessOrderCreation>("/yandex-pay/create-url", params)
}

export const yandexPay = {
  createOrder
}
