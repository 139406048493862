import { pedantMarketApi } from "../clients"
import type { Paginated, Device, DeviceListParams, getPopularModelsParams } from "../types"
import Cookies from 'universal-cookie'
const cookies = new Cookies(null, { path: '/' })

const getList = (params: DeviceListParams) => {
  return pedantMarketApi.get<Paginated<Device>>("/devices", {
    params: {
      ...params
    }
  })
}

const getRecomendationListById = (id: string) => {
  return pedantMarketApi.get<Paginated<Device>>("/recommendations/similar", {params: {Id: id}})
}

const getPopularModels = (params: getPopularModelsParams = {}) => {
  const digi_uc = cookies.get('digi_uc')

  if (digi_uc) params.userHistory = digi_uc

  return pedantMarketApi.get<Paginated<Device>>("/recommendations/recs", { params })
}

const getItem = (deviceId: string) => {
  return pedantMarketApi.get<Device>(`/devices/${deviceId}`)
}

export const devices = {
  getList,
  getRecomendationListById,
  getPopularModels,
  getItem
}
